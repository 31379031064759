<script lang="ts">
import { FieldTypeEnum } from '@/core/enums/field-types';
import years from "@/helpers/yearsOptions";

export default {
  props: {
    selectedClient: { type: Number, default: () => null }
  },
  components: {
    MasterDetail: () => import("@/components/master-detail.vue")
  },
  computed: {
    customResource() {
      const id = this.$props.selectedClient;
      const resource = this.apiResource(`v1/clientes/${id}/csll`);
      return {
        ...resource,
        get(...args) {
          return resource.get(...args).then(result => {
            return result;
          });
        }
      };
    }
  },
  data() {
    return {
      opts: {
        years
      },
      cols: [
        {
          key: "id",
          name: "Código",
          sort: 0,
          hideInform: true,
          hideInTable: true
        },
        {
          key: "aliquota",
          name: "Alíquota",
          colSize: 6,
          type: FieldTypeEnum.DOUBLE,
          rules: [{ rule: "required" }]
        },
        {
          key: "anoBase",
          name: "Ano base",
          sort: 0,
          colSize: 6,
          type: FieldTypeEnum.SELECT,
          rel: { to: "years", key: "value", name: "text" },
          rules: [{ rule: "required" }]
        },
        {
          key: "competenciaDe",
          name: "Competência De",
          sort: 0,
          colSize: 6,
          type: FieldTypeEnum.MONTH,
          rules: [{ rule: "required" }]
        },
        {
          key: "competenciaAte",
          name: "Competência Até",
          sort: 0,
          colSize: 6,
          type: FieldTypeEnum.MONTH,
          rules: [{ rule: "required" }]
        },
        {
          key: "criado_em",
          name: "Criado Em",
          type: FieldTypeEnum.DATETIME,
          hideInform: true
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          type: FieldTypeEnum.DATETIME,
          hideInform: true
        }
      ]
    };
  }
};
</script>

<template>
  <MasterDetail
    formTitle="Cadastro CSLL"
    :cols="cols"
    :opts="opts"
    has-year-filter
    :customResource="customResource"
  >
  </MasterDetail>
</template>
